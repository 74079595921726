import fetch from 'auth/FetchInterceptor'
const { SERVER_PATH } = require('constants/ApiConstant');

const AuthService = {}
const URL_SERVER=`${SERVER_PATH}/api`

AuthService.login = function (data) {
	return fetch({
		url: URL_SERVER+'/auth/login',
		method: 'post',
		data: data
	})
}

AuthService.register = function (data) {
	return fetch({
		url: '/auth/register',
		method: 'post',
		data: data
	})
}

AuthService.logout = function () {
	return fetch({
		url: '/auth/logout',
		method: 'post'
	})
}

AuthService.loginInOAuth = function () {
	return fetch({
		url: '/auth/loginInOAuth',
		method: 'post'
	})
}

export default AuthService;