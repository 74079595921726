import React, { createContext } from "react";
import { useSocket } from '../utils/hooks/useSocket';
const { SERVER_PATH } = require('constants/ApiConstant');

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const { socket, online } = useSocket(SERVER_PATH);
    //const { socket, online } = useSocket('http://ventas.kippi.digital:8080');

    return (
        <SocketContext.Provider value={{ socket, online }}>
            {children}
        </SocketContext.Provider>
    )
}