import { useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeStatus } from 'store/slices/smsSlice';
//import { Buffer } from 'buffer';
import io from 'socket.io-client';

export const useSocket = ( serverPath ) => {
    const socket = useMemo( () => io.connect(serverPath, { transports: ['websocket'] }), [serverPath]);
    const [online, setonline] = useState(false);
    const dispatch = useDispatch();
    
    useEffect(() => {
        setonline(socket.connected);
    }, [ socket ])
      
    useEffect(() => {    
        socket.on('connection', () => {
            setonline(true)
            console.log('Conectado al servidor con sockets...)');
        })
    }, [ socket ])

    useEffect(() => {
        socket.on('stateChangeSMS',(data) => {
            console.log('socket recibido:')
            dispatch(changeStatus(data))            
            // console.log('user:'+data.uuiduser)            
        })
    }, [socket])

    useEffect(() => {
        socket.on('disconnect', () => {
          setonline(false)
        })
    }, [socket])
    
    return {
        socket,
        online
    }
}

