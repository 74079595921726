import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const initialState = {
	status: 'nada',
    uuid_user: '',
    uuid_sms: '',
}

export const smsSlice = createSlice({
	name: 'stateSMS',
	initialState,
	reducers: {
		changeStatus: (state, action) => {            
            state.status = action.payload.state;
            state.uuid_user = action.payload.uuiduser;
            state.uuid_sms = action.payload.uuidsms;
        }
	},
})

export const { 
	changeStatus
} = smsSlice.actions

export default smsSlice.reducer